import { mode } from '@chakra-ui/theme-tools'
import type { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { dnaTheme } from '../..'

const variantPrimarySolid: SystemStyleFunction = (props) => {
  const textColor = mode('light.text.body', 'dark.text.body')(props)

  return {
    ...dnaTheme.components.Badge.variants.primarySolid(props),
    color: textColor,
  }
}

const variantPrimaryOutline: SystemStyleFunction = (props) => {
  const textColor = mode('light.text.heading', 'dark.text.heading')(props)
  const bgColor = mode('light.brand.secondary', 'dark.brand.secondary')(props)

  return {
    ...dnaTheme.components.Badge.variants.primaryOutline(props),
    color: textColor,
    borderColor: bgColor,
  }
}

const variantSecondarySolid: SystemStyleFunction = (props) => {
  const textColor = mode('light.text.heading', 'dark.text.heading')(props)

  return {
    ...dnaTheme.components.Badge.variants.secondarySolid(props),
    color: textColor,
  }
}

const variantSecondaryOutline: SystemStyleFunction = (props) => {
  const textColor = mode('light.text.heading', 'dark.text.heading')(props)

  return {
    ...dnaTheme.components.Badge.variants.secondaryOutline(props),
    color: textColor,
  }
}

const variants = {
  primarySolid: variantPrimarySolid,
  secondarySolid: variantSecondarySolid,
  primaryOutline: variantPrimaryOutline,
  secondaryOutline: variantSecondaryOutline,
}

const defaultProps = {
  variant: 'primarySolid',
}

export const Badge = {
  variants,
  defaultProps,
}
